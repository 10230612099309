import React from 'react';

const FIPDataViewer: React.FC = () => {
    return (
        <div>
            <input type="text" placeholder="Search Term" style={{ marginRight: '8px' }} />
            <button>Search</button>
        </div>
    );
};

export default FIPDataViewer;