export const ConnectorMain = () => {
    
    const getEPOAccessToken = async () => {
        const ConsumerKey = "kMcjy9qhW36VFfU0zKURXRMIq5Uw5bRz";
        const ConsumerSecretKey = "bGhghPruSS3bTfr3";
        let accessToken = "";
    
        const url = "https://ops.epo.org/3.2/auth/accesstoken";
        const postData = "grant_type=" + encodeURIComponent("client_credentials");
        const headers = new Headers();
        headers.append("Authorization", "Basic " + btoa(ConsumerKey + ":" + ConsumerSecretKey));
        headers.append("Content-Type", "application/x-www-form-urlencoded");
    
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: headers,
            body: postData,
        };
    
        try {
            const response = await fetch(url, requestOptions);
            const responseText = await response.text();
            const responseJson = JSON.parse(responseText);
    
            accessToken = responseJson.access_token;
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    
        return accessToken;    
    }

    const getEPOData = async (searchTerm: string, accessToken: string) => {
        const url = `https://ops.epo.org/3.2/rest-services/family/publication/docdb/${searchTerm}`;
        const headers = new Headers();
        headers.append("Authorization", "Bearer " + accessToken);
        headers.append("Accept", "application/json");

        const requestOptions: RequestInit = {
            method: 'GET',
            headers: headers,
        };

        try {
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.error('Error fetching EPO data:', error);
            return null;
        }
    }
    
    return {
        getEPOAccessToken,
        getEPOData,            
    };
};