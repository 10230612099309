import React, { useState } from 'react';
import { ConnectorMain } from '../../libs/connectors/ConnectorMain';

const EPODataViewer: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('US.2009305789');
    const [epoData, setEPOData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedMember, setSelectedMember] = useState<any>(null);

    const handleSearchClick = async () => {
        setLoading(true); // Start loading
        const { getEPOAccessToken, getEPOData } = ConnectorMain();
        const token = await getEPOAccessToken();

        if (token && searchTerm) {
            const data = await getEPOData(searchTerm, token);
            setEPOData(data);
        }
        setLoading(false); // End loading
    };

    const formatDate = (dateString: string) => {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        return `${month}-${day}-${year}`;
    };

    const renderTable = (data: any) => {
        if (!data || !data['ops:world-patent-data']) return null;

        const familyMembers = data['ops:world-patent-data']['ops:patent-family']['ops:family-member'];

        return (
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Pub No</th>
                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Country</th>
                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Kind</th>
                        <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Pub Date</th>
                    </tr>
                </thead>
                <tbody>
                    {familyMembers.map((member: any, index: number) => {
                        const docId = member['publication-reference']['document-id'][0];
                        return (
                            <tr key={index}>
                                <td style={{ border: '1px solid black', padding: '8px' }}>
                                    <a href="#" className="standard-link" onClick={() => setSelectedMember(member)}>
                                        {docId['doc-number']['$']}
                                    </a>
                                </td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{docId['country']['$']}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{docId['kind']['$']}</td>
                                <td style={{ border: '1px solid black', padding: '8px' }}>{formatDate(docId['date']['$'])}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderDetailedView = (member: any) => {
        if (!member) return null;

        const docId = member['publication-reference']['document-id'][0];
        const publicationNumber = docId['doc-number']['$'];

        const renderRow = (key: string, value: any) => (
            <tr key={key}>
                <td style={{ border: '1px solid black', padding: '8px', wordWrap: 'break-word', maxWidth: '150px' }}>{key}</td>
                <td style={{ border: '1px solid black', padding: '8px', wordWrap: 'break-word', maxWidth: '250px' }}>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
            </tr>
        );

        const rows = [];
        for (const key in member) {
            if (member.hasOwnProperty(key)) {
                rows.push(renderRow(key, member[key]));
            }
        }

        return (
            <div style={{ maxWidth: '400px', overflowX: 'auto' }}>
                <button onClick={() => setSelectedMember(null)} style={{ float: 'right', cursor: 'pointer' }}>X</button>
                <h2>Pub No: {publicationNumber}</h2>
                <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Field</th>
                            <th style={{ border: '1px solid black', padding: '8px', backgroundColor: '#f2f2f2' }}>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto' }}>
            <input 
                type="text" 
                placeholder="Search Term" 
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginRight: '8px' }} 
            />
            <button onClick={handleSearchClick}>Search</button>
            <br></br><br></br>
            {loading ? <div className="spinner"></div> : selectedMember ? renderDetailedView(selectedMember) : epoData && renderTable(epoData)}
        </div>
    );
};

export default EPODataViewer;