import React, { useState, useEffect } from 'react';
import { ChevronDoubleLeft16Regular, ChevronDoubleRight16Regular } from '@fluentui/react-icons';
import { useAppSelector, useAppDispatch } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { setDocSettingsOpen } from '../../redux/features/app/appSlice';
import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import EPODataViewer from './EPODataViewer';
import LexisNexisDataViewer from './LexisNexisDataViewer';
import FIPDataViewer from './FIPDataViewer';

interface DocSearchSettingsProps {
    onClose: () => void;
}

interface Node {
    value: string;
    label: string;
    children?: Node[];
}

export var checkedNodesGlobal = "";

export const setcheckedNodesGlobal = (nodelist: string, totalCheckboxes: number) => {
    const numCheckedItems = nodelist ? nodelist.split(",").filter(item => item.trim() !== "").length : 0;
    if (numCheckedItems == totalCheckboxes) {
        nodelist = "";
    }
    checkedNodesGlobal = nodelist;
}

export const DocSearchSettings: React.FC<DocSearchSettingsProps> = ({ onClose }) => {
    const dispatch = useAppDispatch();
    const { showDocSettings } = useAppSelector((state: RootState) => state.app);
    const { docSettingsWidth } = useAppSelector((state: RootState) => state.app);
    const { docSettingsOpen } = useAppSelector((state: RootState) => state.app);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [nodes, setNodes] = useState<Node[]>([]);
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [checked, setChecked] = useState<string[]>(() => {
        const savedCheckedItems = localStorage.getItem(`checkedItems_${selectedId}`);
        return savedCheckedItems ? JSON.parse(savedCheckedItems) : [];
    });
    const [totalCheckboxes, setTotalCheckboxes] = useState<number>(0);
    const { instance, inProgress } = useMsal();
    const account = instance.getActiveAccount();
    const username = account?.username;
    const [isContentExpanded, setIsContentExpanded] = useState<{ [key: string]: boolean }>({
        docSearch: true,
        lexisNexis: false,
        fipData: false
    });
    const [loading, setLoading] = useState<boolean>(true);

    const computeTotalCheckboxes = (nodes: Node[]): number => {
        let total = 0;
        const flattenNodes = (nodes: Node[]) => {
            nodes.forEach((node) => {
                if (!node.children || node.children.length === 0) {
                    total++;
                }
                if (node.children && node.children.length > 0) {
                    flattenNodes(node.children);
                }
            });
        };
        flattenNodes(nodes);
        return total;
    };

    useEffect(() => {
        setTotalCheckboxes(computeTotalCheckboxes(nodes));
    }, [nodes]);

    useEffect(() => {
        const savedCheckedItems = localStorage.getItem(`checkedItems_${selectedId}`);
        if (savedCheckedItems) {
            setChecked(JSON.parse(savedCheckedItems));
        } else {
            setChecked([]);
        }
    }, [selectedId]);

    useEffect(() => {
        localStorage.setItem(`checkedItems_${selectedId}`, JSON.stringify(checked));
    }, [checked, selectedId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
                const response = await fetch(process.env.REACT_APP_BACKEND_URI + 'getMetadataCollections/' + username, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                const nodesData = data.nodes || [];
                setNodes(nodesData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleOnClose = () => {
        onClose();
        const divElement = document.getElementById("docsettings_main_div") as HTMLDivElement | null;
        if (divElement) {
            if (divElement.style.width === "10px") {
                divElement.style.width = "400px";
                divElement.style.minWidth = "400px";
                dispatch(setDocSettingsOpen(true));
                const contentElements = divElement.querySelectorAll('.settings-content') as NodeListOf<HTMLElement>;
                contentElements.forEach(contentElement => {
                    contentElement.style.display = "block";
                });
            } else {
                divElement.style.width = "10px";
                divElement.style.minWidth = "10px";
                dispatch(setDocSettingsOpen(false));
                const contentElements = divElement.querySelectorAll('.settings-content') as NodeListOf<HTMLElement>;
                contentElements.forEach(contentElement => {
                    contentElement.style.display = "none";
                });
            }
        }
    }

    const toggleSettingsIcon = () => {
        if (docSettingsOpen == true) {
            return <ChevronDoubleLeft16Regular
                aria-label="dismiss message"
                onClick={handleOnClose}
                color="black"
                style={{ float: 'right', cursor: 'pointer' }}
            />
        } else {
            return <ChevronDoubleRight16Regular
                aria-label="dismiss message"
                onClick={handleOnClose}
                color="black"
                style={{ float: 'right', cursor: 'pointer' }}
            />
        }
    }

    const handleCheck = (checked: string[]) => {
        setChecked(checked);
        var checkedList = checked.join(", ");
        let totalCheckboxes = 0;
        const flattenNodes = (nodes: Node[]) => {
            nodes.forEach((node) => {
                if (!node.children || node.children.length === 0) {
                    totalCheckboxes++;
                }
                if (node.children && node.children.length > 0) {
                    flattenNodes(node.children);
                }
            });
        };
        flattenNodes(nodes);
        setcheckedNodesGlobal(checkedList, totalCheckboxes);
    }

    const settingsStyle = {
        maxWidth: docSettingsOpen ? (docSettingsWidth as string) : '10px',
        minWidth: docSettingsOpen ? (docSettingsWidth as string) : '10px',
        paddingLeft: '16px',
        paddingTop: '8px',
        paddingRight: '0px',
        border: '1px solid rgb(204, 204, 204)',
        overflow: 'scroll',
        transition: 'max-width 0.3s ease , min-width 0.3s ease',
        height: '100%',
    };

    const connectorStyle = {
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: '4px',
        paddingBottom: '8px',
        borderTop: '2px solid rgb(204, 204, 204)',
        //borderBottom: '1px solid rgb(204, 204, 204)',
        transition: 'max-height 0.3s ease, padding 0.3s ease',
        overflow: 'hidden',
    };

    const toggleContent = (section: string) => {
        setIsContentExpanded(prevState => ({
            docSearch: section === 'docSearch' ? !prevState.docSearch : false,
            lexisNexis: section === 'lexisNexis' ? !prevState.lexisNexis : false,
            fipData: section === 'fipData' ? !prevState.fipData : false,
            epoData: section === 'epoData' ? !prevState.epoData : false
        }));
    };

    const handleCheckboxClick = (event: React.MouseEvent, section: string) => {
        event.stopPropagation();
        // const checkbox = event.target as HTMLInputElement;
        // setIsContentExpanded({
        //     docSearch: section === 'docSearch' && checkbox.checked,
        //     lexisNexis: section === 'lexisNexis' && checkbox.checked,
        //     fipData: section === 'fipData' && checkbox.checked
        // });
        switch(section) {
            case 'docSearch':
                break;
            case 'lexisNexis':
                break;
            case 'fipData':
                break;
        }
    }; 

    return (<>
        {showDocSettings && (
            <div id="docsettings_main_div" style={settingsStyle}>
                
                {toggleSettingsIcon()}
                {/* <h2>Connectors</h2> */}
                <br></br><br></br>
                <div className="settings-content" style={{ ...connectorStyle, maxHeight: isContentExpanded.docSearch ? '100%' : '24px'}}>
                    <h3 style={{ marginBottom: '0', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => toggleContent('docSearch')}>
                        Document Search ({checked.length === totalCheckboxes || checked.length === 0 ? 'ALL' : checked.length})
                        <input type="checkbox" style={{ marginLeft: '8px', marginTop: '6px', verticalAlign: 'middle' }} onClick={(e) => handleCheckboxClick(e, 'docSearch')} />
                    </h3>
                    <div id="warning" style={{ color: 'red', fontSize: '9pt', visibility: checked.length > 700 && checked.length != totalCheckboxes ? 'visible' : 'hidden' }}>
                        WARNING: Max documents search is 700.
                    </div>
                    {loading ? (
                        <div className="spinner"></div>
                    ) : (
                        <CheckboxTree
                            nodes={nodes}
                            checked={checked}
                            expanded={expanded}
                            onCheck={handleCheck}
                            onExpand={expanded => setExpanded(expanded)}
                        />
                    )}
                </div>
                <div className="settings-content" style={{ ...connectorStyle, maxHeight: isContentExpanded.lexisNexis ? '100%' : '24px'}}>
                    <h3 style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => toggleContent('lexisNexis')}>
                        Lexis Nexis
                        <input type="checkbox" style={{ marginLeft: '8px', marginTop: '6px', verticalAlign: 'middle' }} onClick={(e) => handleCheckboxClick(e, 'lexisNexis')} />
                    </h3>
                    <br></br>
                    <LexisNexisDataViewer />
                </div>
                <div className="settings-content" style={{ ...connectorStyle, maxHeight: isContentExpanded.fipData ? '100%' : '24px'}}>
                    <h3 style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => toggleContent('fipData')}>
                        FIP Data
                        <input type="checkbox" style={{ marginLeft: '8px', marginTop: '4px', verticalAlign: 'middle' }} onClick={(e) => handleCheckboxClick(e, 'fipData')} />
                    </h3>
                    <br></br>
                    <FIPDataViewer />
                </div>                
                <div className="settings-content" style={{ ...connectorStyle, maxHeight: isContentExpanded.epoData ? '100%' : '24px'}}>
                    <h3 style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => toggleContent('epoData')}>
                        EPO Family Data
                        <input type="checkbox" style={{ marginLeft: '8px', marginTop: '4px', verticalAlign: 'middle' }} onClick={(e) => handleCheckboxClick(e, 'epoData')} />
                    </h3>
                    <br></br>
                    <EPODataViewer />
                </div>
            </div>
        )}
    </>);
};

export default DocSearchSettings;