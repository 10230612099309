import { FC, useCallback, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
    Avatar,
    Menu,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Persona,
    makeStyles,
    shorthands,
    tokens,
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    //Textarea,
} from '@fluentui/react-components';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { resetState } from '../../redux/app/store';
import { useEffect } from 'react';

const useClasses = makeStyles({
    root: {
        marginRight: '20px',
    },
    persona: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingVerticalMNudge),
        '&:hover': {},
    },
    dialogSurface: {
        maxWidth: '1200px',
        width: '1200px',
        height: '600px',
    },
    textArea: {
        width: '97%',
        height: '150px',
        marginBottom: '10px',
        fontSize:'9pt'
        
    },   
    dropdown: {
        marginBottom: '10px',
    },
    activeButton: {
        backgroundColor: 'blue',
        color: 'white',
        width: '150px',
    },
    inactiveButton: {
        width: '150px',
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    clearButton: {
        // marginLeft: 'auto',
        // marginRight: '35px'
    },    
    saveMessage: {
        //marginLeft: 'auto', 
        color: 'green', 
        paddingLeft: '10px', 
    },    
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    titleButtons: {
        display: 'flex',
        marginRight: '10px', 
    },
    button: {
        marginRight: '10px', 
        width: '150px',
    },  
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        width: '30px',
        height: '30px',
        backgroundColor: 'grey',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: 'none',
        // borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    redButton: {
        width: '150px',
        backgroundColor: 'red',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // border: 'none',
        // borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: 'darkred', // Darker red on hover
            color: 'white'
        },        
    },            
});


interface IUserSettingsProps {
    setLoadingState: () => void;
}

export const UserSettings: FC<IUserSettingsProps> = ({ setLoadingState }) => {
    const classes = useClasses();

    const { instance } = useMsal();

    const account = instance.getActiveAccount();
    const username = account?.username;
    const userid = account?.homeAccountId;

    const isValidUser = window.localStorage.getItem("validUser");

    if (isValidUser == null || isValidUser === "" || isValidUser === "invalid") {
        window.location.replace(`validate.html?username=${username}`);
    } else {
        const isValidUser2 = window.sessionStorage.getItem("validUser");
        if (isValidUser2 == null || isValidUser2 === "" || isValidUser2 === "invalid") {
            window.location.replace(`validate2.html?username=${username}`);
        }
    }

    const serverURI = window.sessionStorage.getItem("serverURI");

    const [saveMessage, setSaveMessage] = useState('');

    const onLogout = useCallback(async () => {
        setLoadingState();
        await AuthHelper.logoutAsync(instance);
        resetState();
    }, [instance, setLoadingState]);

    const [isModalOpen, setModalOpen] = useState(false);
    const [systemPrompt, setSystemPrompt] = useState('');

    const [fewShotQuestion1, setFewShotQuestion1] = useState('');
    const [fewShotAnswer1, setFewShotAnswer1] = useState('');

    const [fewShotQuestion2, setFewShotQuestion2] = useState('');
    const [fewShotAnswer2, setFewShotAnswer2] = useState('');

    const [fewShotQuestion3, setFewShotQuestion3] = useState('');
    const [fewShotAnswer3, setFewShotAnswer3] = useState('');


    const [selectedChatType, setSelectedChatType] = useState('General Chat');

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const handleSystemPromptChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSystemPrompt(event.target.value);
    };

    const handleFewShotQuestion1Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFewShotQuestion1(event.target.value);
    };

    const handleFewShotAnswer1Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFewShotAnswer1(event.target.value);
    };

    const handleFewShotQuestion2Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFewShotQuestion2(event.target.value);
    };

    const handleFewShotAnswer2Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFewShotAnswer2(event.target.value);
    };
    
    const handleFewShotQuestion3Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFewShotQuestion3(event.target.value);
    };

    const handleFewShotAnswer3Change = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFewShotAnswer3(event.target.value);
    };    

    const handleSystemPromptSubmit = () => {
        if (selectedChatType === 'General Chat') {
            window.localStorage.setItem("GeneralChatSystemPrompt", systemPrompt);
        } else {
            window.localStorage.setItem("DocumentsChatSystemPrompt", systemPrompt);
            window.localStorage.setItem("DocumentsChatFewShotQuestion1", fewShotQuestion1);
            window.localStorage.setItem("DocumentsChatFewShotAnswer1", fewShotAnswer1);
            window.localStorage.setItem("DocumentsChatFewShotQuestion2", fewShotQuestion2);
            window.localStorage.setItem("DocumentsChatFewShotAnswer2", fewShotAnswer2);
            window.localStorage.setItem("DocumentsChatFewShotQuestion3", fewShotQuestion3);
            window.localStorage.setItem("DocumentsChatFewShotAnswer3", fewShotAnswer3);                        
        }
        setSaveMessage('Your changes have been saved.');
        setTimeout(() => setSaveMessage(''), 3000);
    };

    const handleSystemPromptClear = () => {
        // setSystemPrompt('');
        // setFewShotQuestion1('');

        if(selectedChatType === "General Chat") {
            window.localStorage.setItem("GeneralChatSystemPrompt", '');
            setSystemPrompt(process.env.REACT_APP_GENERAL_SYSTEM_PROMPT || '')
        }
        else {
            window.localStorage.setItem("DocumentsChatSystemPrompt", '');
            window.localStorage.setItem("DocumentsChatFewShotQuestion1", ''); 
            window.localStorage.setItem("DocumentsChatFewShotAnswer1", '');
            window.localStorage.setItem("DocumentsChatFewShotQuestion2", '');
            window.localStorage.setItem("DocumentsChatFewShotAnswer2", '');
            window.localStorage.setItem("DocumentsChatFewShotQuestion3", '');
            window.localStorage.setItem("DocumentsChatFewShotAnswer3", '');  
            setSystemPrompt(process.env.REACT_APP_DOCUMENTS_SYSTEM_PROMPT || '')
            setFewShotQuestion1(process.env.REACT_APP_FEWSHOT_EXAMPLE_QUESTION1 || ''); 
            setFewShotAnswer1(process.env.REACT_APP_FEWSHOT_EXAMPLE_ANSWER1 || ''); 
            setFewShotQuestion2(process.env.REACT_APP_FEWSHOT_EXAMPLE_QUESTION2 || ''); 
            setFewShotAnswer2(process.env.REACT_APP_FEWSHOT_EXAMPLE_ANSWER2 || ''); 
            setFewShotQuestion3(process.env.REACT_APP_FEWSHOT_EXAMPLE_QUESTION3 || ''); 
            setFewShotAnswer3(process.env.REACT_APP_FEWSHOT_EXAMPLE_ANSWER3 || '');                         
        }
        
     
    };

    // const handleChatTypeChange = (_: any, option: any) => {
    //     const selectedType = option.value;
    //     alert(selectedType);
    //     setSelectedChatType(selectedType);
    
    //     if (selectedType === 'General Chat') {
    //         const savedPrompt = window.localStorage.getItem("GeneralChatSystemPrompt") || '';
    //         setSystemPrompt(savedPrompt);
    //         setFewShotExamples('');
    //     } else {
    //         const savedPrompt = window.localStorage.getItem("DocumentsChatSystemPrompt") || '';
    //         const savedExamples = window.localStorage.getItem("DocumentsChatFewShotExamples") || '';
    //         setSystemPrompt(savedPrompt);
    //         setFewShotExamples(savedExamples);
    //     }
    // };
    

    const systemPromptClick = () => {
        // if(username?.toLowerCase() == 'tunger@ktslaw.com' || username?.toLowerCase() == 'cbeasley@ktslaw.com' || username?.toLowerCase() == 'boblack@ktslaw.com') {
        //     setSelectedChatType("General Chat");
        //     setModalOpen(true);
        // }
        // else {
        //     alert('Ability to edit System Prompts ... Coming Soon!');
        //     return;
        // }

            setSelectedChatType("General Chat");
            setModalOpen(true);        


    };

    const handleGeneralChatClick = () => {
        setSelectedChatType("General Chat");
     
    };    

    const handleDocumentsChatClick = () => {
        setSelectedChatType("Documents Chat");     
    };   
    
    const isFewShotDisabled = selectedChatType === 'General Chat';

    useEffect(() => {
        
        if(selectedChatType === 'General Chat' || !selectedChatType) {
            
            const savedGeneralChatPrompt = window.localStorage.getItem("GeneralChatSystemPrompt");

            if (!savedGeneralChatPrompt) {
                setSystemPrompt(process.env.REACT_APP_GENERAL_SYSTEM_PROMPT || '')
            } else {
                setSystemPrompt(savedGeneralChatPrompt || '');
            }            
            setFewShotQuestion1('');
            setFewShotAnswer1('');
            setFewShotQuestion2('');
            setFewShotAnswer2('');
            setFewShotQuestion3('');
            setFewShotAnswer3('');            
        }
        else
        {   
            const savedDocumentsChatPrompt = window.localStorage.getItem("DocumentsChatSystemPrompt");
            const savedFewShotQuestion1 = window.localStorage.getItem("DocumentsChatFewShotQuestion1");
            const savedFewShotAnswer1 = window.localStorage.getItem("DocumentsChatFewShotAnswer1");
            const savedFewShotQuestion2 = window.localStorage.getItem("DocumentsChatFewShotQuestion2");
            const savedFewShotAnswer2 = window.localStorage.getItem("DocumentsChatFewShotAnswer2");
            const savedFewShotQuestion3 = window.localStorage.getItem("DocumentsChatFewShotQuestion3");
            const savedFewShotAnswer3 = window.localStorage.getItem("DocumentsChatFewShotAnswer3");                        

            if (savedDocumentsChatPrompt) {
                setSystemPrompt(savedDocumentsChatPrompt || '');
            } else {
                
                setSystemPrompt(process.env.REACT_APP_DOCUMENTS_SYSTEM_PROMPT || '')
            }

            ////

            if (savedFewShotQuestion1) {
                setFewShotQuestion1(savedFewShotQuestion1 || '');
            }
            else
            {
                setFewShotQuestion1(process.env.REACT_APP_FEWSHOT_EXAMPLE_QUESTION1 || ''); 
            }

            if (savedFewShotAnswer1) {
                setFewShotAnswer1(savedFewShotAnswer1 || '');
            }
            else
            {
                setFewShotAnswer1(process.env.REACT_APP_FEWSHOT_EXAMPLE_ANSWER1 || ''); 
            }     
            
            ////

            if (savedFewShotQuestion2) {
                setFewShotQuestion2(savedFewShotQuestion2 || '');
            }
            else
            {
                setFewShotQuestion2(process.env.REACT_APP_FEWSHOT_EXAMPLE_QUESTION2 || ''); 
            }

            if (savedFewShotAnswer2) {
                setFewShotAnswer2(savedFewShotAnswer2 || '');
            }
            else
            {
                setFewShotAnswer2(process.env.REACT_APP_FEWSHOT_EXAMPLE_ANSWER2 || ''); 
            } 

            ////


            if (savedFewShotQuestion3) {
                setFewShotQuestion3(savedFewShotQuestion3 || '');
            }
            else
            {
                setFewShotQuestion3(process.env.REACT_APP_FEWSHOT_EXAMPLE_QUESTION3 || ''); 
            }

            if (savedFewShotAnswer3) {
                setFewShotAnswer3(savedFewShotAnswer3 || '');
            }
            else
            {
                setFewShotAnswer3(process.env.REACT_APP_FEWSHOT_EXAMPLE_ANSWER3 || ''); 
            }             
        }
            
    }, [selectedChatType, isModalOpen] );  
    
    const handleTextareaClick = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        event.target.select();
    };

    const handleDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };
    
    const confirmDelete = () => {
        setDeleteConfirmationOpen(false);
        deleteAllChats();
        window.location.replace("index.html");
    };
    
    const cancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };
    
    function deleteAllChats() {
        
        const serverURI = window.sessionStorage.getItem("serverURI");
        //alert(userid + " " + serverURI);

        fetch(serverURI + 'chatSession/deleteAllChats/' + userid)
        .then(response => {
            if (response.ok) {
                alert('All chats have been deleted.');
            }
            else {
                throw new Error('Network response was not ok ' + response.statusText);
            }
        })
        // .then(data => {
        //     //console.log('Delete All Chats Response:', data);
        // })
        .catch(error => {
            console.error('Error deleting all chats:', error);
        });        
    }

    const handleDeleteConfirmationOpenChange = (data: { open: boolean }) => {
        setDeleteConfirmationOpen(data.open);
    };

    return (
        <div>
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Avatar
                        className={classes.root}
                        key={account?.name ?? account?.username}
                        name={account?.name ?? account?.username}
                        size={28}
                        badge={{ status: 'available' }}
                    />
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem className={classes.persona}>
                            <Persona
                                name={account?.name ?? account?.username}
                                secondaryText={account?.username}
                                avatar={{ color: 'colorful' }}
                            />
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={() => window.open("https://dmsweb.kilpatricktownsend.com/work/web/dialogs/link/d/USADMIN!12596463.4", "_blank")}>Kwery Help</MenuItem>
                        <MenuDivider />
                        {/* <MenuItem onClick={() => window.open(`${serverURI}healthz`, "_blank")}>Check Server</MenuItem>
                        <MenuDivider /> */}
                        <MenuItem onClick={systemPromptClick}>Settings</MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={onLogout}>Log Out</MenuItem>
                        <MenuDivider />
                        <div style={{ fontSize: '8pt', textAlign: 'right', marginBottom: '-5px' }}>Version 1.6</div>
                    </MenuList>
                </MenuPopover>
            </Menu>

            <Dialog open={isDeleteConfirmationOpen} onOpenChange={(_, data) => handleDeleteConfirmationOpenChange(data)}>
    <DialogSurface>
        <DialogTitle>Delete All Chats</DialogTitle>
        <DialogContent>
            Are you sure you want to delete all chats? This action cannot be undone.
        </DialogContent>
        <DialogActions>
            <Button onClick={cancelDelete}>Cancel</Button>
            <Button appearance="primary" onClick={confirmDelete}>Delete</Button>
        </DialogActions>
    </DialogSurface>
</Dialog>
          
            
            {isModalOpen && (
    <Dialog open={isModalOpen} onOpenChange={(_, { open }) => setModalOpen(open)}>
        <DialogSurface className={classes.dialogSurface}>
            <button
                style={{border: "none", borderRadius: "5px", fontFamily:"cursive", paddingBottom: "2px"}}
                className={classes.closeButton}
                onClick={() => setModalOpen(false)}
            >
                X
            </button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1>Settings</h1>
            </div>
            <hr></hr>
            <br></br>
            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
                <DialogTitle style={{width:'750px'}}>System Prompt</DialogTitle>
                <div style={{ paddingLeft: '20px', height: '100%' }}>
                    <Button
                        onClick={handleGeneralChatClick}
                        className={selectedChatType === "General Chat" ? classes.activeButton : classes.inactiveButton}
                        style={{ margin: '0 10px' }}
                    >
                        General Chat
                    </Button>
                    <Button
                        onClick={handleDocumentsChatClick}
                        className={selectedChatType === "Documents Chat" ? classes.activeButton : classes.inactiveButton}
                        style={{ margin: '0 10px' }}
                    >
                        Documents Chat
                    </Button>
                </div>
            </div>            
            <DialogContent style={{ minHeight: "93%"}}>
                <br />
                <div style={{paddingLeft:'20px'}}>
                    <span style={{display:"none"}}>System Prompt:</span>
                    <textarea
                        value={systemPrompt}
                        onChange={handleSystemPromptChange}
                        className={classes.textArea}
                        onFocus={handleTextareaClick}
                    />
                </div>

                <span style={{display:"none"}}>Few Shot Question 1:</span>
                <textarea 
                    style={{display:"none"}} 
                    value={fewShotQuestion1}
                    onChange={handleFewShotQuestion1Change}
                    className={classes.textArea}
                    disabled={isFewShotDisabled}
                    onFocus={handleTextareaClick}
                />
                <span style={{display:"none"}}>Few Shot Answer 1:</span>
                <textarea
                    style={{display:"none"}}
                    value={fewShotAnswer1}
                    onChange={handleFewShotAnswer1Change}
                    className={classes.textArea}
                    disabled={isFewShotDisabled}
                    onFocus={handleTextareaClick}
                />
                <span style={{display:"none"}}>Few Shot Question 2:</span>
                <textarea
                    style={{display:"none"}}
                    value={fewShotQuestion2}
                    onChange={handleFewShotQuestion2Change}
                    className={classes.textArea}
                    disabled={isFewShotDisabled}
                    onFocus={handleTextareaClick}
                />
                <span style={{display:"none"}}>Few Shot Answer 2:</span>
                <textarea
                    style={{display:"none"}}
                    value={fewShotAnswer2}
                    onChange={handleFewShotAnswer2Change}
                    className={classes.textArea}
                    disabled={isFewShotDisabled}
                    onFocus={handleTextareaClick}
                />
                <span style={{display:"none"}}>Few Shot Question 3:</span>
                <textarea
                    style={{display:"none"}}
                    value={fewShotQuestion3}
                    onChange={handleFewShotQuestion3Change}
                    className={classes.textArea}
                    disabled={isFewShotDisabled}
                    onFocus={handleTextareaClick}
                />
                <span style={{display:"none"}}>Few Shot Answer 3:</span>
                <textarea
                    style={{display:"none"}}
                    value={fewShotAnswer3}
                    onChange={handleFewShotAnswer3Change}
                    className={classes.textArea}
                    disabled={isFewShotDisabled}
                    onFocus={handleTextareaClick}
                />
                <div style={{paddingRight:'40px', display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                    {saveMessage && <span className={classes.saveMessage} style={{paddingRight:'20px'}}>{saveMessage}</span>}
                    <Button className={classes.inactiveButton} onClick={handleSystemPromptSubmit}>Save</Button>&nbsp;&nbsp;&nbsp;
                    <Button className={classes.inactiveButton} onClick={handleSystemPromptClear} >Restore Default</Button>
                    
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
                    <DialogTitle style={{width:'910px'}}>Check Server</DialogTitle>
                    <div style={{ paddingLeft: '20px', height: '100%' }}>
                        <Button
                            onClick={() => window.open(`${serverURI}healthz`, "_blank")}
                            className={classes.inactiveButton}
                            style={{ margin: '0 10px' }}
                        >
                            Check Server
                        </Button>
                    </div>
                </div>   
                <br></br>
                <hr></hr>
                <br></br>      
                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '20px' }}>
                    <DialogTitle style={{width:'910px'}}>Delete All Chats</DialogTitle>
                    <div style={{ paddingLeft: '20px', height: '100%' }}>
                        <Button
                            onClick={handleDeleteConfirmation}
                            className={classes.redButton}
                            style={{ margin: '0 10px' }}
                        >
                            Delete All
                        </Button>
                    </div>
                </div>                                          
                <DialogActions></DialogActions>
            </DialogContent>
        </DialogSurface>
    </Dialog>


    
)}

        </div>
    );
};
